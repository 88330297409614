import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#191F2A",
        padding: "1rem",
        color: "#ffffff",
      }}
    >
      <Typography variant="body2">
        <Link
          component="button"
          variant="body2"
          onClick={() => handleNavigate("/privacy-policy")}
          sx={{
            color: "#ffffff",
            textDecoration: "none",
            "&:visited, &:hover, &:active": {
              color: "#ffffff",
            },
          }}
        >
          Privacy Policy
        </Link>
        {"   "}-{"   "}
        <Link
          component="button"
          variant="body2"
          onClick={() => handleNavigate("/legal")}
          sx={{
            color: "#ffffff",
            textDecoration: "none",
            "&:visited, &:hover, &:active": {
              color: "#ffffff",
            },
          }}
        >
          Legal
        </Link>
        {"   "}-{"   "}
        <Link
          component="button"
          variant="body2"
          onClick={() => handleNavigate("/terms-and-conditions")}
          sx={{
            color: "#ffffff",
            textDecoration: "none",
            "&:visited, &:hover, &:active": {
              color: "#ffffff",
            },
          }}
        >
          Terms and Conditions
        </Link>{" "}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
        }}
      >
        <Typography variant="body2">
          <Box component="span" sx={{ fontWeight: "bold" }}>
            The Sport Insider
          </Box>
        </Typography>
        <Typography variant="body2" sx={{ marginLeft: "0.5rem" }}>
          2023
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
