import React, { useState, useEffect } from "react";
import moment from "moment";
import { db } from "../firebase2";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore"; // Added missing imports
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";

import Comments from "./Comments";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { updateDoc, deleteDoc } from "firebase/firestore";

import "./Home.css";
import TipCard from "../components/TipCard";
const auth = getAuth();

function Home({ user }) {
  const [tips, setTips] = useState([]);
  const [userGroup, setUserGroup] = useState(null);
  const [loadedUserData, setLoadedUserData] = useState(false);

  const [openComments, setOpenComments] = useState(false);
  const [selectedTipId, setSelectedTipId] = useState(null);
  const [commentCounts, setCommentCounts] = useState({});
  const [comments, setComments] = useState([]);
  const [loadingTips, setLoadingTips] = useState(true);

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedDateUi, setSelectedDateUi] = useState(
    moment().format("DD.MM.YY")
  );
  const [userPackage, setUserPackage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUserData();
  }, [user]);

  useEffect(() => {
    if (loadedUserData) {
      setTips([]);
      setLoadingTips(true);
      const fetchTips = async () => {
        const tipsByDate = await getTipsByDate(selectedDate);
        setTips(tipsByDate);
        setLoadingTips(false);
      };
      fetchTips();
    }
  }, [selectedDate, loadedUserData]);

  useEffect(() => {
    setTips([]);
    const fetchTips = async () => {
      const tipsByDate = await getTipsByDate(selectedDate);
      setTips(tipsByDate);
      setLoadingTips(false); // Set loadingTips state to false after fetching tips
    };
    fetchTips();
    loadUserData();
  }, [selectedDate, user]);

  const editTip = async (tipId, updatedTipData) => {
    const tipRef = doc(db, "tips", tipId);
    await updateDoc(tipRef, updatedTipData);
  };

  const deleteTip = async (tipId) => {
    const tipRef = doc(db, "tips", tipId);
    await deleteDoc(tipRef);
  };

  const handleDeleteTip = async (tipId) => {
    console.log("delete tip", tipId);
    if (window.confirm("Möchten Sie diesen Tipp wirklich löschen?")) {
      await deleteTip(tipId);
      const updatedTips = tips.filter((tip) => tip.id !== tipId);
      setTips(updatedTips);
    }
  };

  const handleOpenComments = async (tipId) => {
    setSelectedTipId(tipId);
    setOpenComments(true);

    const commentsRef = collection(db, "tips", tipId, "comments");
    const q = query(commentsRef, orderBy("timestamp", "asc"));
    const querySnapshot = await getDocs(q);
    const fetchedComments = [];
    querySnapshot.forEach((doc) => {
      const comment = doc.data();
      comment.id = doc.id;
      fetchedComments.push(comment);
    });
    setComments(fetchedComments);
  };

  const handleCloseComments = () => {
    setOpenComments(false);
  };

  const loadUserData = async () => {
    if (user && user.email) {
      const userRef = doc(db, "users", user.email);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        setUserPackage(userDoc.data().userPackage);
        setUserGroup(userDoc.data().userGroup);
        setLoading(false);
        setLoadedUserData(true);
      } else {
        setUserPackage(null);
        setUserGroup(null);
        setLoading(false);
        setLoadedUserData(true);
      }
    } else {
      setUserPackage(null);
      setUserGroup(null);
      setLoading(false);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const getTipsByDate = async (date) => {
    const formattedDate = moment(date).format("DD.MM.YY");

    const q = query(
      collection(db, "tips"),
      where("date", "==", formattedDate),
      orderBy("time")
    );
    const querySnapshot = await getDocs(q);

    const tips = [];

    for (const doc of querySnapshot.docs) {
      const tip = doc.data();
      tip.id = doc.id;
      tip.status = tip.homePoints > tip.awayPoints ? "win" : "lose";

      if (userPackage === "Premium") {
        const commentsRef = collection(db, "tips", tip.id, "comments");
        const commentsSnapshot = await getDocs(commentsRef);
        tip.commentCount = commentsSnapshot.size;
      }

      tips.push(tip);
    }

    return tips;
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={4}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {loadedUserData && user && userPackage !== "Premium" ? (
              <form
                action="https://us-central1-bettingprofessor-94851.cloudfunctions.net/createCheckoutSession"
                method="POST"
              >
                <input
                  type="hidden"
                  name="priceId"
                  value={"price_1MzpxfLIBIivTKpBYRMaafhX"}
                />
                <input
                  type="hidden"
                  name="email"
                  value={user ? user.email : ""}
                />
                <Button
                  id="checkout-and-portal-button"
                  type="submit"
                  size="large"
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  Buy Premium 4,99€/week
                </Button>
              </form>
            ) : null}
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              style={{ marginTop: "20px" }}
            />
            <Box mt={4}>
              <Grid container spacing={4} justifyContent="center">
                {tips.map((tip, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    {loadingTips ? (
                      <CircularProgress />
                    ) : (
                      <TipCard
                        tip={tip}
                        user={user}
                        userPackage={userPackage}
                        userGroup={userGroup}
                        commentCounts={commentCounts}
                        handleOpenComments={handleOpenComments}
                        handleDeleteTip={handleDeleteTip}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </Box>
      <Comments
        open={openComments}
        handleClose={handleCloseComments}
        tipId={selectedTipId}
        userPackage={userPackage}
      />
    </>
  );
}

export default Home;
