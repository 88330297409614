import { useState, useEffect } from "react";
import { db } from "../firebase2";
import { collection, query, where, getDocs } from "firebase/firestore";
import "./Dashboard.css";
import { Container, Typography, Box, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textAlign: "center",
    color: theme.palette.primary.contrastText,
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  featureItem: {
    margin: theme.spacing(1),
  },
}));

function Dashboard({ user }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [userPackage, setUserPackage] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const q = query(
          collection(db, "users"),
          where("email", "==", user.email)
        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          setCustomerId(doc.data().stripeCustomerId);
          setUserPackage(doc.data().userPackage);
        });

        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    if (user) {
      loadUserData();
    }
  }, [user]);

  const handleManageSubscription = async () => {
    try {
      const response = await fetch(
        "https://us-central1-bettingprofessor-94851.cloudfunctions.net/createPortalSession",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer_id: customerId,
          }),
        }
      );

      const data = await response.json();
      window.location.href = data.url;
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper} style={{}}>
            <div class="account-information">
              <h2 class="account-title">Account Information</h2>
              <ul class="account-details">
                <li class="account-detail">
                  <span class="account-label">Email:</span>
                  <span class="account-value">{user.email}</span>
                </li>
                <li class="account-detail">
                  <span class="account-label">Current Plan:</span>
                  <span class="account-value">{userPackage}</span>
                </li>
              </ul>
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: "10px" }}>
            <div class="subscription-information">
              <h2 class="subscription-title">Subscription Information</h2>
              <ul class="subscription-details">
                <li class="subscription-detail">
                  <button
                    class="manage-subscription-btn"
                    onClick={() => handleManageSubscription()}
                  >
                    Manage My Subscription
                  </button>
                </li>
              </ul>
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: "10px" }}>
            <div class="support-contact">
              <h2 class="support-title">Support & Contact</h2>
              <ul class="support-details">
                <li class="support-detail">
                  <p>
                    <span class="support-value">
                      <a
                        href="https://t.me/BettingCrewApp"
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Telegram
                      </a>
                    </span>
                  </p>
                  <p>
                    <span class="support-value">
                      <a
                        href="mailto: info@thesportinsiders.com"
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        info@thesportinsiders.com
                      </a>
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} style={{ minHeight: "180px" }}>
            <div class="news-article">
              <div class="news-header">
                <h2 class="news-title">Welcome</h2>
                <p class="news-meta">By Admin | April 18, 2023 | Welcome</p>
              </div>
              <div class="news-body"></div>
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: "10px" }}>
            <div class="patch-notes">
              <h2 class="patch-title">v1.0 Release Notes</h2>
              <div class="patch-version">
                <h3 class="patch-version-title">v1.0</h3>
                <ul class="patch-version-changes">
                  <li>Introducing v1.0 - our first release.</li>
                </ul>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
