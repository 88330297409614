import React from "react";
import { Container, Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Privacy Policy
        </Typography>
      </Box>
      <p>Last updated: April 20, 2023</p>
      <Typography variant="body1" paragraph>
        1. Introduction
        <p>
          This Privacy Policy explains how The Sport Insiders ("us", "we", or
          "our") collects, uses, and shares your personal information when you
          use our website, thesportinsiders.com (the "Service"). We encourage
          you to read this Privacy Policy carefully and contact us if you have
          any questions or concerns.
        </p>
        <p>
          By using the Service, you agree to the collection and use of your
          personal information in accordance with this Privacy Policy.
        </p>
        2. Information We Collect
        <p>
          We collect personal information you provide to us when you use the
          Service, including:
        </p>
        <p>
          <ul>
            <li>
              Registration Information: When you create an account, we collect
              your email address and password.
            </li>
            <li>
              Payment Information: When you purchase a premium membership, our
              third-party payment processor, Stripe, collects your payment
              information. We do not store your payment information on our
              systems.
            </li>
            <li>
              Usage Data: We collect information about how you use the Service,
              such as the pages you visit and the actions you take.
            </li>
          </ul>
        </p>
        3. How We Use Your Information
        <p>We use your personal information for the following purposes:</p>
        <p>
          <ul>
            <li>
              To provide and maintain the Service: We use your registration
              information to create and manage your account, provide customer
              support, and communicate with you about the Service.
            </li>
            <li>
              To process payments: Our third-party payment processor, Stripe,
              uses your payment information to process transactions for your
              premium membership.
            </li>
            <li>
              To improve the Service: We analyze usage data to identify trends,
              improve the Service's features and functionality, and provide a
              better user experience.
            </li>
            <li>
              To communicate with you: We use your email address to send you
              important notices about the Service, respond to your inquiries,
              and provide customer support.
            </li>
          </ul>
        </p>
        4. Legal Basis for Processing (for users in the EU/EEA)
        <p>
          If you are a user located in the European Union (EU) or European
          Economic Area (EEA), our legal basis for collecting and using your
          personal information is as follows:
        </p>
        <p>
          <ul>
            <li>
              To perform a contract with you: We process your registration
              information to fulfill our contractual obligations to provide and
              maintain the Service.
            </li>
            <li>
              Legitimate interests: We process your usage data to improve the
              Service and provide a better user experience. We also process your
              email address to communicate with you about the Service and
              provide customer support.
            </li>
            <li>
              Consent: In some cases, we may ask for your consent to process
              your personal information, such as when you sign up for our
              newsletter.
            </li>
          </ul>
        </p>
        5. How We Share Your Information
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your personal information with third parties in the
          following circumstances:
        </p>
        <p>
          <ul>
            <li>
              With service providers: We may share your personal information
              with third-party service providers who help us operate the
              Service, such as Stripe for payment processing.
            </li>
            <li>
              As required by law: We may disclose your personal information if
              required to do so by law or in response to a legal request, such
              as a subpoena, court order, or government demand.
            </li>
          </ul>
        </p>
        6. Data Security
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of data
          transmission or storage is 100% secure, and we cannot guarantee the
          absolute security of your personal information.
        </p>
        7. Data Retention
        <p>
          We retain your personal information for as long as necessary to
          provide the Service, comply with our legal obligations, resolve
          disputes, and enforce our agreements. In accordance with German data
          protection laws, we will delete your personal information upon
          request, unless we are required to retain it for legal reasons.
        </p>
        8. Your Rights (for users in the EU/EEA)
        <p>
          If you are a user located in the EU or EEA, you have certain rights
          under data protection laws, including the right to:
        </p>
        <p>
          <ul>
            <li>Access your personal information.</li>
            <li>
              Request the correction or deletion of your personal information.
            </li>
            <li>Object to the processing of your personal information.</li>
            <li>
              Request the restriction of processing your personal information.
            </li>
            <li>
              Request the transfer of your personal information to another
              organization or directly to you.
            </li>
            <li>
              Withdraw your consent to the processing of your personal
              information, where consent was the legal basis for processing.
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us using the contact
            information provided at the end of this Privacy Policy. We will
            respond to your request within one month, in accordance with
            applicable data protection laws.
          </p>
        </p>
        9. International Data Transfers
        <p>
          Your personal information may be transferred to and processed in
          countries outside of Germany, including but not limited to the United
          States, in accordance with applicable data protection laws and this
          Privacy Policy. By using the Service, you consent to the transfer and
          processing of your personal information in countries outside of
          Germany.
        </p>
        10. Children's Privacy
        <p>
          The Service is not intended for use by individuals under the age of
          18. We do not knowingly collect personal information from children
          under 18. If we become aware that a child under 18 has provided us
          with personal information, we will take steps to delete such
          information.
        </p>
        11. Changes to This Privacy Policy
        <p>
          We may update this Privacy Policy from time to time. We will provide
          reasonable notice of any material changes, and the updated Privacy
          Policy will be posted on this page. By continuing to use the Service
          after any changes to this Privacy Policy, you agree to be bound by the
          revised policy.
        </p>
        12. Contact Us
        <p>
          If you have any questions or concerns about this Privacy Policy, your
          personal information, or your rights under data protection laws,
          please contact us at [info@thesportinsiders.com].
        </p>
      </Typography>
      {/* Add more content related to your Privacy Policy here */}
    </Container>
  );
};

export default PrivacyPolicy;
