import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import BurgerMenu from "./BurgerMenu";
import { getAuth, signOut } from "firebase/auth";
import logo from "../assets/logo.png";
const auth = getAuth();
const useStyles = makeStyles({
  toolbar: {
    justifyContent: "space-between",
  },
  logoContainer: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
});

const Navbar = ({ user, onLogout }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userNav, setUserNav] = useState([user]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setUserNav("login");
      setLoading(false);
    } else {
      setUserNav([]);
      setLoading(false);
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      onLogout(); // Call the onLogout callback function
      navigate("/");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <img src={logo} alt="logo" width={70} />
        </div>
        <div className="navbar-links">
          {loading ? (
            <></>
          ) : (
            <>
              <Button color="inherit" onClick={() => navigate("/")}>
                Events
              </Button>
              {userNav === "login" && (
                <>
                  <Button
                    color="inherit"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </Button>
                  <Button color="inherit" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              )}
              {userNav != "login" && (
                <>
                  <Button color="inherit" onClick={() => navigate("/register")}>
                    Register
                  </Button>
                  <Button color="inherit" onClick={() => navigate("/login")}>
                    Login
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        <BurgerMenu user={user} onLogout={onLogout} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
