import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase2";
import {
  getDoc,
  setDoc,
  doc,
  query,
  where,
  collection,
  getDocs,
} from "firebase/firestore";

const auth = getAuth();

function Register() {
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checked) {
      setError("Please accept the Terms and Conditions and Privacy Policy.");
      return;
    }
    try {
      // Check if name already exists in Firestore
      const usersCollectionRef = collection(db, "users");
      const querySnapshot = await getDocs(
        query(usersCollectionRef, where("name", "==", name))
      );

      if (!querySnapshot.empty) {
        setError("This name is already taken. Please choose a different name.");
        return;
      }

      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Add user to Firestore
      await setDoc(doc(db, "users", user.email), {
        uid: user.uid,
        name: name,
        email: user.email,
      });

      setError("");
      setSuccess(true);
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Register
        </Typography>
      </Box>
      {error && (
        <Box mt={2} mb={2}>
          <Typography color="error" align="center">
            {error}
          </Typography>
        </Box>
      )}
      {success && (
        <Box mt={2} mb={2}>
          <Typography color="success" align="center">
            Registration successful!
          </Typography>
        </Box>
      )}
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Name"
          type="text"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={handleEmailChange}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={handlePasswordChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleCheckboxChange}
            />
          }
          label={
            <>
              I accept the{" "}
              <a href="/terms-and-conditions">Terms and Conditions</a> and{" "}
              <a href="/privacy-policy">Privacy Policy</a>.
            </>
          }
        />
        <Box mt={2} mb={2}>
          <Button variant="contained" color="primary" fullWidth type="submit">
            Register
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" align="center">
            Already have an account? <a href="/login">Login</a>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;
