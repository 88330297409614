import React from "react";
import { Container, Box, Typography } from "@mui/material";

const Legal = () => {
  return (
    <Container maxWidth="md">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Impressum
        </Typography>
      </Box>
      <Typography variant="body1" paragraph>
        <Typography variant="h6">Service Provider:</Typography>
        <p>Isidev GmbH</p>
        <p>Bergmannstr. 191A</p>
        <p>Gelsenkirchen 45886</p>
        <p>Germany</p>
        <Typography variant="h6">Contact:</Typography>
        <p>Email: info@thesportinsiders</p>
        <Typography variant="h6">Responsible for Content:</Typography>
        <p>Can Isikli</p>
        <Typography variant="h6">Register entry:</Typography>
        <p>
          <p>
            Entry in: Handelsregister <p></p>Register Number: HRB 15818
          </p>
          <p>Register Court: Amtsgericht Gelsenkirchen</p>
        </p>
        <Typography variant="h6">VAT ID:</Typography>
        <p>
          VAT indentification number in accorance with Section 27 a of the
          German VAT act: <p>DE332868714</p>
        </p>
        <Typography variant="h6">Liability for Content:</Typography>
        <p>
          As a service provider, we are responsible for our content on these
          pages according to § 7 para.1 TMG (German Telemedia Act) and general
          laws. However, according to §§ 8 to 10 TMG, we are not obliged to
          monitor transmitted or stored third-party information or to
          investigate circumstances indicating illegal activity.
        </p>
        <p>
          Obligations to remove or block the use of information according to
          general laws remain unaffected. Liability in this regard is only
          possible from the point in time at which we become aware of a specific
          legal violation. As soon as we become aware of such violations, we
          will remove this content immediately.
        </p>
        <Typography variant="h6">Copyright:</Typography>
        <p>
          The content and works on these pages created by the site operator are
          subject to German copyright law. Duplication, processing,
          distribution, or any form of commercialization of such material beyond
          the scope of the copyright law shall require the prior written consent
          of its respective author or creator. Downloads and copies of this site
          are only permitted for private, non-commercial use.
        </p>
        <p>
          Insofar as the content on this site was not created by the operator,
          the copyrights of third parties are respected. In particular,
          third-party content is identified as such. Should you nevertheless
          become aware of a copyright infringement, please inform us
          accordingly. As soon as we become aware of any infringements, we will
          remove such content immediately.
        </p>
      </Typography>
      {/* Add more content related to your Legal Terms and Conditions here */}
    </Container>
  );
};

export default Legal;
