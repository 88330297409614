import React, { useState } from "react";
import { MdMenu, MdClose } from "react-icons/md";
import "./BurgerMenu.css";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();

const BurgerMenu = ({ user, onLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    const body = document.querySelector("body");
    body.classList.toggle("noscroll");
  };

  const handleLogout = async () => {
    await signOut(auth);

    onLogout(); // Call the onLogout callback function
    navigate("/login");
  };
  const renderNavLinks = () => {
    if (user) {
      // If user is logged in
      return (
        <>
          <a href="/" onClick={toggleMenu}>
            Events
          </a>
          <a href="/dashboard" onClick={toggleMenu}>
            Dashboard
          </a>
          <button
            onClick={() => {
              toggleMenu();
              handleLogout();
            }}
          >
            Logout
          </button>
        </>
      );
    } else {
      // If user is not logged in
      return (
        <>
          <a href="/" onClick={toggleMenu}>
            Events
          </a>
          <a href="/register" onClick={toggleMenu}>
            Register
          </a>
          <a href="/login" onClick={toggleMenu}>
            Login
          </a>
        </>
      );
    }
  };

  return (
    <div className="burger-menu">
      <div onClick={toggleMenu}>
        <MdMenu size={30} />
      </div>
      {menuOpen && (
        <div className="menu">
          <div className="menu-close" onClick={toggleMenu}>
            <MdClose size={30} />
          </div>
          {renderNavLinks()}
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
