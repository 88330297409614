import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebase2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    zIndex: 1,
    right: theme.spacing(-53),
    top: theme.spacing(-0.5),
    color: theme.palette.grey[500],
    margin: 0, // Add this line to remove any extra margin
    padding: 0, // Add this line to remove any extra padding
  },
  commentItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: "8px",
    backgroundColor: theme.palette.grey[800],
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
  },
  commentAuthor: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  commentText: {
    whiteSpace: "pre-wrap",
  },
  commentTimestamp: {
    color: theme.palette.grey[600],
    fontSize: "0.8rem",
  },
  form: {
    padding: theme.spacing(1),
  },
  formInput: {
    flex: 1,
  },
  formButton: {
    flexShrink: 0,
    marginTop: theme.spacing(1),
  },
  dialogActions: {},
}));

const auth = getAuth();

function Comments({ open, handleClose, tipId, userPackage }) {
  const classes = useStyles();

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  useEffect(() => {
    setComment("");
    if (userPackage === "Premium") {
      if (tipId) {
        const commentsRef = collection(db, "tips", tipId, "comments");
        const q = query(commentsRef, orderBy("timestamp", "asc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const fetchedComments = [];
          querySnapshot.forEach((doc) => {
            const comment = doc.data();
            comment.id = doc.id;
            fetchedComments.push(comment);
          });
          setComments(fetchedComments);
        });

        return () => {
          unsubscribe();
        };
      }
    }
  }, [tipId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (comment.trim()) {
      const user = auth.currentUser;
      if (user) {
        const email = user.email;
        const userDocRef = doc(db, "users", email);
        const userDocSnapshot = await getDoc(userDocRef);
        const userName = userDocSnapshot.data().name;
        const commentsRef = collection(db, "tips", tipId, "comments");
        await addDoc(commentsRef, {
          name: userName,
          text: comment,
          timestamp: new Date(),
        });
        setComment("");
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Comments
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.root}>
        <List>
          {userPackage === "Premium" &&
            comments.map((comment, index) => (
              <ListItem
                key={index}
                className={classes.commentItem}
                alignItems="flex-start"
              >
                <ListItemText
                  primary={
                    <span className={classes.commentAuthor}>
                      {comment.name}
                    </span>
                  }
                  secondary={
                    <>
                      <span className={classes.commentText}>
                        {comment.text}
                      </span>
                      <br />
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.commentTimestamp}
                      >
                        {new Date(comment.timestamp?.toDate()).toLocaleString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          {userPackage != "Premium" && (
            <span>Upgrade to Premium to see comments</span>
          )}
        </List>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            label="Add Comment"
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            className={classes.formInput}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.formButton}
          >
            Send
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default Comments;
