import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
} from "@mui/material";

function TipCard({
  tip,
  user,
  userPackage,
  userGroup,
  commentCounts,
  handleOpenComments,
  handleDeleteTip,
}) {
  return (
    <>
      <Card style={{ minHeight: "320px" }}>
        <CardHeader
          title={`${tip.sport}`}
          subheader={`${tip.date} | ${tip.time} | ${tip.league}`}
          subheaderTypographyProps={{ variant: "body2" }}
          style={{ textAlign: "center" }}
        />
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CardContent style={{ width: "100%", textAlign: "center" }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6">
                {tip.sport === "MLB" ||
                tip.sport === "NBA" ||
                tip.sport === "NFL" ||
                tip.sport === "NHL"
                  ? `${tip.away} @ ${tip.home}`
                  : `${tip.home} vs. ${tip.away}`}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  margin: "10px 0",
                  filter:
                    (user && userPackage === "Premium") ||
                    tip.picktype === "free"
                      ? "none"
                      : "blur(5px)",
                }}
              >
                {(user && userPackage === "Premium") || tip.picktype === "free"
                  ? `Odds: ${tip.odds}`
                  : "?.??"}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  margin: "10px 0",
                  filter:
                    (user && userPackage === "Premium") ||
                    tip.picktype === "free"
                      ? "none"
                      : "blur(5px)",
                }}
              >
                {(user && userPackage === "Premium") || tip.picktype === "free"
                  ? `${tip.pick}`
                  : "Not Premium"}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" mt={2}>
              <Box display="flex" justifyContent="flex-end" mt={1}>
                {userPackage === "Premium" && (
                  <Typography variant="caption">{`${
                    tip.commentCount || "0"
                  } Comments`}</Typography>
                )}
                {userPackage !== "Premium" && (
                  <Typography variant="caption">{`${
                    tip.commentCount || "?"
                  } Comments`}</Typography>
                )}
              </Box>
              <Button
                onClick={() => handleOpenComments(tip.id)}
                variant="contained"
                color="primary"
                size="small"
              >
                Comments
              </Button>
            </Box>
          </CardContent>
        </Box>
      </Card>
      <Box width="100%" position={"relative"} top={-350} left={0}>
        {userGroup === "AdminTSI" && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              onClick={() => handleDeleteTip(tip.id)}
              variant="contained"
              color="secondary"
              size="small"
            >
              X
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

export default TipCard;
