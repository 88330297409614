import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD0lMjbND0YVUNbGBa5Ch_-pOSiTkvKF9k",
  authDomain: "bettingprofessor-94851.firebaseapp.com",
  projectId: "bettingprofessor-94851",
  storageBucket: "bettingprofessor-94851.appspot.com",
  messagingSenderId: "349765927897",
  appId: "1:349765927897:web:c5f13d315c61921953cfc8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
