import React, { useState } from "react";
import { Container, Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const auth = getAuth();

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      sessionStorage.setItem("token", token);

      setError("");
      navigate("/");
    } catch (error) {
      console.error(error);
      setError("Invalid email or password");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Login
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        {error && (
          <Box mt={2} mb={2}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Box>
        )}
        <Box mt={2} mb={2}>
          <Button variant="contained" color="primary" fullWidth type="submit">
            Login
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="body2">
            Don't have an account? <a href="/register">Register</a>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
