import React from "react";
import { Container, Box, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Terms and Conditions
        </Typography>
      </Box>
      <p>Last updated: April 20, 2023</p>
      <Typography variant="body1" paragraph>
        <p>
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the thesportinsiders.com website
          (the "Service") operated by The Sport Insiders ("us", "we", or "our").
        </p>
        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users, and others who access or use the Service.
        </p>
        <p>
          By accessing or using the Service, you agree to be bound by these
          Terms. If you disagree with any part of the terms, then you may not
          access the Service.
        </p>
        <Typography variant="h6">1. Description of Service</Typography>
        <p>
          The Sport Insiders provides information, opinions, and analysis
          related to sports events through its premium membership service. By
          purchasing a premium membership, you gain access to exclusive content,
          including expert opinions, analysis, and insights about various sports
          events.
        </p>
        <Typography variant="h6">2. Registration and Accounts</Typography>
        <p>
          To access the premium content on our Service, you must create an
          account and purchase a premium membership. You agree to provide
          accurate, current, and complete information during the registration
          process and to update such information as needed. You are responsible
          for maintaining the confidentiality of your password and account
          information and are responsible for all activities that occur under
          your account.
        </p>
        <Typography variant="h6">3. Premium Membership</Typography>
        <p>
          Premium membership fees are stated on our website and may be subject
          to change without prior notice. All fees are non-refundable and
          non-transferable. Your premium membership will automatically renew at
          the end of each billing cycle unless you cancel your membership before
          the renewal date.
        </p>
        <Typography variant="h6">4. Payment</Typography>
        <p>
          By purchasing a premium membership, you agree to pay the applicable
          fees using a valid payment method. We reserve the right to refuse or
          cancel any transaction if we suspect fraud or unauthorized use of your
          payment method. Payments for premium memberships are processed through
          Stripe, a third-party payment processor. By using Stripe to make
          payments on our website, you agree to comply with Stripe's terms and
          conditions, privacy policy, and any other applicable policies. The
          Sport Insiders is not responsible for any issues, errors, or disputes
          arising from your use of Stripe to process payments.
        </p>
        <Typography variant="h6">5. Cancellation and Termination</Typography>
        <p>
          You may cancel your premium membership at any time by accessing your
          account settings or by contacting us. Cancellation will take effect at
          the end of the current billing cycle, and you will not be charged
          again. No refunds will be issued for unused portions of your
          membership.
        </p>
        <p>
          We reserve the right to suspend or terminate your access to the
          Service at any time, with or without cause, and with or without
          notice.
        </p>
        <p>
          We reserve the right to suspend or terminate your access to the
          Service at any time, with or without cause, and with or without
          notice.
        </p>
        <Typography variant="h6">6. Intellectual Property</Typography>
        <p>
          All content on the Service, including but not limited to text,
          graphics, images, and logos, is the property of The Sport Insiders or
          its content suppliers and is protected by copyright, trademark, and
          other intellectual property laws. You may not reproduce, distribute,
          or create derivative works from our content without our prior written
          consent.
        </p>
        <Typography variant="h6">7. Limitation of Liability</Typography>
        <p>
          The Sport Insiders does not guarantee the accuracy, completeness, or
          usefulness of the information provided on the Service. You acknowledge
          that using the information provided is at your own risk. The Sport
          Insiders shall not be liable for any losses, damages, or injury
          resulting from your use of the Service or your reliance on any content
          provided.
        </p>
        <Typography variant="h6">8. Indemnification</Typography>
        <p>
          You agree to indemnify, defend, and hold harmless The Sport Insiders,
          its affiliates, officers, directors, employees, and agents from and
          against any and all claims, damages, losses, liabilities, costs, and
          expenses (including reasonable attorneys' fees) arising from your use
          of the Service, your violation of these Terms, or your infringement of
          any intellectual property or other rights of any person or entity.
        </p>
        <Typography variant="h6">9. Governing Law</Typography>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of Germany, without regard to its conflict of law provisions.
        </p>
        <Typography variant="h6">10. Changes</Typography>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. We will provide reasonable notice of any
          material changes. By continuing to access or use our Service after any
          changes to these Terms, you agree to be bound by the revised terms. If
          you do not agree to the new terms, please stop using the Service.
        </p>
        <Typography variant="h6">
          11. Compliance with Applicable Laws
        </Typography>
        <p>
          You agree to use the Service in compliance with all applicable laws
          and regulations, including but not limited to any laws and regulations
          governing the sharing of information and opinions about sports events
          in your jurisdiction. You are solely responsible for ensuring that
          your use of the Service is in compliance with all such laws and
          regulations.
        </p>
        <Typography variant="h6">12. Data Protection</Typography>
        <p>
          By using the Service, you agree to the collection and use of your
          personal data in accordance with our Privacy Policy. You acknowledge
          that your personal data may be transferred to and processed in
          countries outside of Germany, including but not limited to the United
          States, in accordance with applicable data protection laws and our
          Privacy Policy.
        </p>
        <Typography variant="h6">13. Dispute Resolution</Typography>
        <p>
          Any dispute, claim, or controversy arising out of or relating to these
          Terms or the breach, termination, enforcement, interpretation, or
          validity thereof, including the determination of the scope or
          applicability of this agreement to arbitrate, shall be determined by
          arbitration in Germany before a single arbitrator. The arbitration
          shall be administered by a recognized arbitration institution under
          its rules, as amended by these Terms. Judgment on the award may be
          entered in any court having jurisdiction. This clause shall not
          preclude parties from seeking provisional remedies in aid of
          arbitration from a court of appropriate jurisdiction.
        </p>
        <Typography variant="h6">14. Contact Us</Typography>
        <p>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at [info@thesportinsiders.com].
          </p>
          <p>
            By using thesportinsiders.com and purchasing a premium membership,
            you acknowledge that you have read, understood, and agree to be
            bound by these Terms and Conditions.
          </p>
        </p>
      </Typography>
      {/* Add more content related to your Legal Terms and Conditions here */}
    </Container>
  );
};

export default TermsAndConditions;
