import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Legal from "./pages/Legal";
import Layout from "./components/Layout";
import animationData from "./assets/animation2.json";
import Lottie from "react-lottie";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
const auth = getAuth();

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function App() {
  const [user, setUser] = useState(null);

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="lottie-container-bg">
        <Lottie options={defaultOptions} />
      </div>
      <Router>
        <AppContent user={user} setUser={setUser} onLogout={handleLogout} />
      </Router>
    </ThemeProvider>
  );
}

function AppContent({ user, setUser, onLogout }) {
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setUser(null);
      }

      if (user) {
        setUser(user);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout user={user} onLogout={onLogout}>
            <Home user={user} />
          </Layout>
        }
      />
      <Route
        path="/login"
        element={
          <Layout user={user}>
            <Login />
          </Layout>
        }
      />
      <Route
        path="/register"
        element={
          <Layout user={user}>
            <Register />
          </Layout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <Layout user={user} onLogout={onLogout}>
            <Dashboard user={user} />
          </Layout>
        }
      />
      <Route
        path="/legal"
        element={
          <Layout user={user}>
            <Legal />
          </Layout>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <Layout user={user}>
            <TermsAndConditions />
          </Layout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Layout user={user}>
            <PrivacyPolicy />
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
